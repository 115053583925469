<template>
	<v-container>
		<Progress :error="error" :loading="loading" type="overlay"></Progress>
		<material-card v-if="!loading" title="Мобильное приложение">
			Мы создали приложения для Android и Android TV которое повторяет основные функции
			портала: просмотр сериалов, аниме, фильмов. Приложение пока плохо оттестировано и может содержать множество ошибок, просьба сообщать о них тех. поддержке.<br><br>
			<a :href="'https://cdn1.atoto.ru/app/atoto-' + version + '.apk'"
				>Скачать
				<span class="small">(версия {{ version }})</span></a
			>
			<br><br>Либо можете установить из <a href="https://play.google.com/store/apps/details?id=ru.video.atoto">Google play</a>.
		</material-card>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			loading: true,
			version: null,
			error: null,
		};
	},
	created() {
		this.fetchData();
	},
	methods: {
		fetchData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/last-version", {}, function (data, error) {
				t.loading = false;
				if (!error) t.version = data;
				else t.error = error;
			});
		},
	},
};
</script>
<style scoped>
.small {font-size: 80%;}
.container {
	max-width: 1100px;
}
</style>